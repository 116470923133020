import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Error404.css';

const Error404 = () => {
  return (
    <div className="error-page">
      <div className="error-content">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>Oops! The page you're looking for seems to have wandered off into uncharted territory.</p>
        <Link to="/" className="home-button">
          Return to Homepage
        </Link>
      </div>
    </div>
  );
};

export default Error404;
