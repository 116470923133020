import React, { createContext, useContext, useState, useCallback } from "react";

const UIContext = createContext(null);

export const UIProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);
  const [alertType, setAlertType] = useState("");

  const showAlert = useCallback((message, type = "info") => {
    setAlert(message);
    setAlertType(type);
  }, []);

  const handleNewAlert = useCallback((alert) => {
    setAlert(alert);
  }, []);

  const handleAlertType = useCallback((type) => {
    setAlertType(type);
  }, []);

  const clearAlert = useCallback(() => {
    setAlert(null);
    setAlertType("");
  }, []);

  return (
    <UIContext.Provider
      value={{ 
        alert, 
        alertType, 
        handleNewAlert, 
        handleAlertType,
        showAlert,
        clearAlert
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => {
  const context = useContext(UIContext);
  if (!context) {
    throw new Error("useUI must be used within a UIProvider");
  }

  const showSuccess = useCallback((message) => {
    context.showAlert(message, "success");
  }, [context]);

  const showError = useCallback((message) => {
    context.showAlert(message, "error");
  }, [context]);

  const showInfo = useCallback((message) => {
    context.showAlert(message, "info");
  }, [context]);

  const showWarning = useCallback((message) => {
    context.showAlert(message, "warning");
  }, [context]);

  return {
    ...context,
    showSuccess,
    showError,
    showInfo,
    showWarning
  };
};
