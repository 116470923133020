import React from 'react';
import '../styles/common.css';
import '../styles/Team.css';
import '../styles/Partners.css';
import cheloniaLogo from '../chelonia.png';
import tplLogo from '../tpl.svg';
import estuarioLogo from '../estuario.png';
import CleanMiamiBeach from '../CleanMiamiBeach.avif';
import Favela from '../Favela.png';
import IronboundCC from '../IronboundCC.png';
import Miccosukee from '../Miccosukee.png';

function MeetTheTeam() {
  const partners = [
    { 
      name: "Chelonia", 
      logo: cheloniaLogo,
      url: "https://www.cheloniapr.org/"
    },
    { 
      name: "Trust for Public Land", 
      logo: tplLogo,
      url: "https://www.tpl.org/"
    },
    { 
      name: "Estuario", 
      logo: estuarioLogo,
      url: "https://www.estuario.org/"
    },
    { 
      name: "Clean Miami Beach", 
      logo: CleanMiamiBeach,
      url: "https://www.cleanmiamibeach.org/"
    },
    { 
      name: "Favela United Tour", 
      logo: Favela,
      url: "https://www.favelaunitedtour.com/"
    },
    { 
      name: "Ironbound Community Corporation", 
      logo: IronboundCC,
      url: "https://ironboundcc.org/"
    },
    { 
      name: "Miccosukee Tribe of Indians of Florida", 
      logo: Miccosukee,
      url: "https://www.miccosukee.com/"
    }
  ];

  return (
    <div className="team-container">
      <div className="team-section-wrapper">
        <h3 className="team-subtitle"></h3>
        <div className="team-member">
          <img src="/ama-headshot.JPG" alt="Isiah Cruz, a man smiling with his mouth closed in front of a nature background" className="team-photo" />
          <h3 className="team-name">Isiah Cruz</h3>
          <p className="team-position">Founder & CEO</p>
          <a href="https://www.linkedin.com/in/isiahcruz/" target="_blank" rel="noopener noreferrer">
            <img src="/linkedin-logo.png" alt="LinkedIn" className="linkedin-logo" />
          </a>
        </div>
      </div>

      <div className="team-section-wrapper">
        <h3 className="team-subtitle">Partnered With</h3>
        <div className="partners-container">
          <div className="partners-wrapper">
            {partners.map((partner, index) => (
              <a 
                key={index} 
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                className="partner-item"
              >
                <img 
                  src={partner.logo} 
                  alt={`${partner.name} logo`} 
                  className="partner-logo" 
                />
              </a>
            ))}
            {/* Duplicate partners for seamless scroll */}
            {partners.map((partner, index) => (
              <a 
                key={`duplicate-${index}`} 
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                className="partner-item"
              >
                <img 
                  src={partner.logo} 
                  alt={`${partner.name} logo`} 
                  className="partner-logo" 
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetTheTeam;