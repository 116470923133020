import React from 'react';
import '../styles/common.css';
import '../styles/MissionVision.css';
import logo from '../images/ama-logo.png';
import photo1 from "../images/photo1.jpg";
import photo2 from "../images/photo2.jpg";


function MissionVisionPage() {
  return (
    <div className="mission-vision-page-container">
      <div className="video-background">
        <video autoPlay muted loop playsInline className="background-video">
          <source src="/amavideo.MP4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay"></div>
      </div>
      <div className="content-wrapper">
        <div className="mission-section">
          <div className="section-content-wrapper">
            <div className="text-content">
              <h2 className="section-title">Our Mission</h2>
              <div className="section-text">
                <p>Revolutionizing environmental restoration through innovation, technology, and community-driven impact.</p>
                <br />
                <p>Ama Earth Group is a purpose-driven, for-profit organization incorporated in Borikén (Puerto Rico).</p>
              </div>
            </div>
            <img 
              src={photo1} 
              alt="Two people facing away from the camera, far in the distance, both lifting a fallen tree. Both people are wearing dark clothes and hats." 
              className="mission-image"
            />
          </div>
        </div>
        
        <div className="vision-section">
          <div className="section-content-wrapper">
            <img 
              src={photo2} 
              alt="Three people on a beach, one person is close to the camera smiling with a hand in the sand. The other two are in the background, looking way from the camera." 
              className="vision-image"
            />
            <div className="text-content">
              <h2 className="section-title">Our Vision</h2>
              <div className="section-text">
                <p>To reclaim technological leadership through Indigenous innovation—restoring balance to the Earth with AI-driven climate solutions.</p>
              </div>
            </div>
          </div>
        </div>
        <img src={logo} alt="AMA Logo, a black background with a silver abstract image" className="mission-vision-logo" />
      </div>
    </div>
  );
}

export default MissionVisionPage; 