import { useState, useEffect, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useUI } from '../utils/UIContext';

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const AlertBar = () => {
  const { alert, alertType, clearAlert, showSuccess, showError, showInfo, showWarning } = useUI();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(Boolean(alert));
  }, [alert]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    clearAlert();
  };

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={4000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        '& .MuiAlert-root': {
          width: '100%',
          maxWidth: '600px',
          boxShadow: 3,
        }
      }}
    >
      <Alert 
        onClose={handleClose} 
        severity={alertType || "info"} 
        sx={{ 
          width: "100%",
          '& .MuiAlert-message': {
            fontSize: '1rem'
          }
        }}
      >
        {alert}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
