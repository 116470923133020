import React from 'react';
import '../styles/common.css';
import '../styles/Product.css';
import squid1 from '../images/squid1.png';

function Product() {
  return (
    <div className="product-section">
      <div className="content-wrapper">
        <h2 className="section-title">Squid: AI-Powered Environmental Impact Assessment (EIA) Tool</h2>
        <div className="intro-section">
          <p className="section-text">
            Our AI-powered platform Squid streamlines workflow for climate tech professionals while generating funding for on-the-ground restoration projects.
          </p>
          <p className="section-text">
            Environmental Impact Assessments (EIAs) are critical for ensuring that development projects align with sustainability goals and regulatory requirements. However, the traditional EIA process is often labor-intensive, requiring extensive research, data synthesis, and compliance verification.
          </p>
        </div>
        <br></br>
        <br></br>
        <div className="product-intro">
          <div className="intro-text">
            <p className="section-text">
              Squid leverages advanced natural language processing (NLP) and multimodal AI to process environmental regulations, technical reports, and site-specific data in real time. Consultants can upload various data sources, including text documents, PDFs, voice notes, photos, and videos, allowing Squid to extract key insights and generate structured EIA reports.
            </p>
            <p className="section-text">
              With interactive AI refinement features, Squid allows users to engage in real-time adjustments, ensuring that reports are tailored to specific project needs. It also fosters collaboration among teams, enabling seamless data sharing and peer review.
            </p>
          </div>
          <div className="product-image-container">
            <a 
              href="https://squid.amaearthgroup.com//" 
              target="_blank" 
              rel="noopener noreferrer"
              className="product-image-link"
            >
              <img src={squid1} alt="Screenshot of Squid AI Platform" className="product-image" />
            </a>
          </div>
        </div>
        <div className="product-details">
          <p className="section-text">
            Ama Earth Group's Squid isn't just a tool—it's a game-changer for environmental professionals, accelerating the path toward more sustainable, data-driven decision-making in impact assessments.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Product; 