import React from 'react';
import '../styles/common.css';
import '../styles/Indigenous.css';
import logo from '../images/ama-logo.png';

function Indigenous() {
  return (
    <div className="indigenous-container">
      <img src={logo} alt="AMA Logo, a black background with a silver abstract image" className="indigenous-logo" />
      <div className="content-wrapper">
        <h2 className="section-title">Indigenous Statement</h2>
        <div className="section-text">
          <p>
            We acknowledge that we are on the sacred lands of Borikén, the ancestral and unceded homeland of the Taíno people. We honor the enduring presence, resilience, and wisdom of the Taíno and all Indigenous peoples who have cared for this land, its waters, and all living beings since time immemorial.
          </p>
          <br />
          <p>
            As stewards of this earth, we recognize our responsibility to uphold Indigenous sovereignty, respect traditional ecological knowledge, and work towards healing and restoring the lands, waters, and communities impacted by colonization and environmental harm.
          </p>
          <br />
          <p>
            At Ama Earth Group, we are committed to integrating Indigenous values into our work, uplifting Indigenous voices, and taking actionable steps toward environmental justice and regeneration. We express deep gratitude to the ancestors, the spirits of the land, and the communities that continue to fight for a just and sustainable future.
          </p>
          <br />
          <p>
            May we walk gently, listen deeply, and act with integrity in our commitment to the land and all who call it home.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Indigenous; 