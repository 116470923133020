export const projectsData = {
  projects: [
    {
      title: "Leatherback Turtles in Puerto Rico 🐢",
      location: "Loíza, Puerto Rico",
      coordinates: {
        lat: 18.4313,
        lng: -65.8801
      },
      img: "leatherback.jpeg",
      problem: "Leatherback Turtles are facing a concerning decline in their populations across the globe, with a global population decline of 40% over the past three generations. Certain Pacific populations are now less than 1% of their size in 1980. Threats include bycatch in fishing gear, marine pollution, coastal development affecting nesting beaches, and climate change.",
      solution: "We are currently helping Leatherback Turtle populations on the northern coast of Puerto Rico by setting up Turtle Nest Identification poles on the beach and protecting Turtle Nests from predators & human disturbance.",
      leading_org: ["Ama Earth Group", "Chelonia"],
      leading_org_url: ["https://amaearthgroup.com/", "https://www.cheloniapr.org/"],
      dedicated_funds: 300,
      contributing_org_name: "El Caracol Restaurant",
      contributing_org_url: "https://www.facebook.com/ElCaracolRestaurant?paipv=0&eav=Afbqpxn3xdtDGgGPnSPSd8QapeqNkNAWt4jqwFcREWbf0_A3J7rL2HdR_LCbVm3yAiI&_rdr",
      share: "https://www.instagram.com/p/C28Kr-GpTRg/?img_index=1",
      progress_title: "Project #2 September 2023",
      progress_url: "https://www.instagram.com/p/CxvtkpHJC7H/?img_index=1"
    },
    {
      title: "Laguna del Condado, Puerto Rico 🇵🇷",
      location: "San Juan, Puerto Rico",
      coordinates: {
        lat: 18.4571,
        lng: -66.0832
      },
      img: "condado_laguna.jpg",
      problem: "The communities of Condado and Miramar in San Juan, Puerto Rico, are frequently subjected to flooding, causing significant damage to infrastructure, homes, and natural habitats. Urbanization, sea level rise, and extreme weather events exacerbate the risk of flooding in these areas. Without effective intervention, flooding will continue to pose a threat to public safety, property, and the environment.",
      solution: "We are addressing the flooding challenges in Condado and Miramar by planting mangroves along the shoreline of Laguna del Condado. Mangroves serve as a natural barrier, reducing the impact of waves and storm surges, stabilizing shorelines, and absorbing excess water. By restoring and expanding mangrove habitats, we are helping to mitigate flooding, enhance coastal resilience, and protect the communities and ecosystems of Condado and Miramar.",
      leading_org: ["Estuario", "Ama Earth Group"],
      leading_org_url: ["https://www.estuario.org", "https://amaearthgroup.com/"],
      dedicated_funds: 400,
      contributing_org_name: "The Condado Plaza Hotel",
      contributing_org_url: "https://www.hilton.com/en/hotels/sjucohh-the-condado-plaza-hotel/?SEO_id=GMB-AMER-HH-SJUCOHH&y_source=1_MTIxNjk1NTItNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      share: "https://www.instagram.com/p/CwauNfcJg9v/?img_index=1",
      progress_title: "TBD",
      progress_url: "TBD"
    },
    {
      title: "Passaic River, New Jersey 🇺🇸",
      location: "Newark, New Jersey",
      coordinates: {
        lat: 40.7357,
        lng: -74.1724
      },
      img: "passaic_river.jpeg",
      problem: "The Passaic River in Newark, NJ faces significant environmental challenges including invasive species overgrowth, particularly Japanese Knotweed, which threatens native plant populations. The river also suffers from pollution and litter accumulation, affecting both wildlife and water quality. These issues impact the local ecosystem and community access to clean waterways.",
      solution: "We are leading ongoing nature healing efforts including: (1) Removal of invasive plant species (Japanese Knotweed) , (2) Planting of native plant species (e.g. Tussock Sedge, Buttonbush, etc) , and (3) Trash cleanups along the Passaic River in Newark, NJ",
      leading_org: ["Trust for Public Land", "Ironbound Community Corporation", "Ama Earth Group"],
      leading_org_url: ["https://www.tpl.org/", "https://ironboundcc.org/", "https://amaearthgroup.com/"],
      dedicated_funds: 150,
      contributing_org_name: ["Revolution Tints", "Belle Arte Tattoo"],
      contributing_org_url: ["https://maps.app.goo.gl/zSdDeaxCAxDgGCEp9", "https://maps.app.goo.gl/QCeeZoyVDWrR46WT7"],
      share: "https://www.instagram.com/p/CywKROuJ2Cn/?img_index=1",
      progress_title: "Project #3 November 2023",
      progress_url: "https://www.instagram.com/p/CzUINqipQXJ/?img_index=1"
    },
    {
      title: "Otamiri River, Nigeria 🇳🇬",
      location: "Owerri, Nigeria",
      coordinates: {
        lat: 5.4836,
        lng: 7.0332
      },
      img: "/otamari.jpg",
      img_credit: "Photo: \"Otamiri River\" by <a href='https://commons.wikimedia.org/wiki/File:OTAMIRI_RIVER.jpg' target='_blank' rel='noopener noreferrer'>INNOCENT CHIDERA SHAMMAH</a>, licensed under <a href='https://creativecommons.org/licenses/by-sa/4.0/' target='_blank' rel='noopener noreferrer'>CC BY-SA 4.0</a>.",
      problem: "The Otamiri River in Owerri, Nigeria faces severe water pollution from industrial waste, agricultural runoff, and urban development. The river's ecosystem is threatened by sedimentation, reduced water quality, and loss of native species. These environmental challenges impact both local communities and wildlife that depend on the river.",
      solution: "We are leading ongoing nature healing efforts including: (1) Removal of invasive plant species (Japanese Knotweed) , (2) Planting of native plant species (e.g. Tussock Sedge, Buttonbush, etc) , and (3) Trash cleanups along the Passaic River in Newark, NJ",
      leading_org: ["Ama Earth Group", "Imo River Basin Development Authority (AIRBDA)"],
      leading_org_url: ["https://www.amaearthgroup.com", "https://www.manpower.com.ng/company/866/anambra-imo-river-basin-development-authority"],
      dedicated_funds: 250,
      contributing_org_name: "N/A",
      contributing_org_url: "",
      share: "https://www.instagram.com/p/C1xDN-nij9h/?img_index=1",
      progress_title: "Project #4 January 2024",
      progress_url: "https://www.instagram.com/p/C2Am6m2OjSs/?img_index=1"
    },
    {
      title: "Lagoa Rodrigo de Freitas, Brazil 🇧🇷",
      location: "Rio de Janeiro, Brazil",
      coordinates: {
        lat: -22.9747,
        lng: -43.2075
      },
      img: "lagoa_rodrigo.jpeg",
      problem: "Lagoa Rodrigo de Freitas in Rio de Janeiro faces urban development pressures and environmental degradation. The lagoon suffers from water quality issues, loss of native vegetation, and reduced biodiversity. These challenges affect both the local ecosystem and the community's ability to enjoy this important urban waterway.",
      solution: "We are leading ongoing nature healing efforts including: (1) Green roof installations with native plants for households in Rocinha, Rio de Janeiro",
      leading_org: ["Ama Earth Group"],
      leading_org_url: ["https://www.amaearthgroup.com"],
      dedicated_funds: 100,
      contributing_org_name: "Favela United Tour",
      contributing_org_url: "https://www.favelaunitedtour.com/",
      share: "https://www.instagram.com/p/CxT14FAJ3bL/?img_index=1",
      progress_title: "Project #2 September 2023",
      progress_url: "https://www.instagram.com/p/CxvtkpHJC7H/?img_index=1"
    }
  ],
  events: [
    {
      title: "Invasive Species Removal 🌾",
      date_starttime: "2024-06-02T14:00:00Z",
      info: "Removing invasive plants and replacing them with native river plants. Simultaneously conducting a litter pickup for the area.",
      date_endtime: "2024-06-02T18:00:00Z",
      location: "TBD",
      project_id: 3
    }
  ]
}; 