import React, { useState } from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import worldAtlas from "../countries-110m.json";
import { projectsData } from "../data/projects";
import "../styles/MyMap.css";

function MyMap({ onProjectSelect }) {
  const [selectedProjects, setSelectedProjects] = useState(null);

  // Group nearby projects
  const getGroupedProjects = () => {
    const THRESHOLD = 1; // Degree threshold for considering points "close"
    const groups = [];
    const usedIndices = new Set();

    try {
      projectsData.projects.forEach((project, index) => {
        if (usedIndices.has(index)) return;

        const group = {
          projects: [project],
          coordinates: project.coordinates,
        };

        // Check for nearby projects
        projectsData.projects.forEach((otherProject, otherIndex) => {
          if (index === otherIndex || usedIndices.has(otherIndex)) return;

          const latDiff = Math.abs(project.coordinates.lat - otherProject.coordinates.lat);
          const lngDiff = Math.abs(project.coordinates.lng - otherProject.coordinates.lng);

          if (latDiff < THRESHOLD && lngDiff < THRESHOLD) {
            group.projects.push(otherProject);
            usedIndices.add(otherIndex);
          }
        });

        usedIndices.add(index);
        groups.push(group);
      });

      return groups;
    } catch (error) {
      console.error("Error processing project data:", error);
      return [];
    }
  };

  const handleMarkerClick = (group) => {
    setSelectedProjects(group.projects);
    // Pass all projects in the group to the parent component
    if (onProjectSelect) {
      onProjectSelect(group.projects);
    }
  };

  const groupedProjects = getGroupedProjects();

  return (
    <div className="map-container">
      <ComposableMap 
        projectionConfig={{ 
          scale: 150,
          center: [0, 0]
        }}
        style={{ backgroundColor: "#000000" }}
      >
        <Geographies geography={worldAtlas}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: { 
                    fill: "#2C2C2C", 
                    stroke: "#FFFFFF", 
                    strokeWidth: 0.5,
                    pointerEvents: "none"
                  },
                  hover: { 
                    fill: "#2C2C2C", 
                    stroke: "#FFFFFF", 
                    strokeWidth: 0.5,
                    pointerEvents: "none"
                  },
                  pressed: { 
                    fill: "#2C2C2C", 
                    stroke: "#FFFFFF", 
                    strokeWidth: 0.5,
                    pointerEvents: "none"
                  },
                }}
              />
            ))
          }
        </Geographies>

        {groupedProjects.map((group, index) => (
          <Marker
            key={index}
            coordinates={[group.coordinates.lng, group.coordinates.lat]}
            onClick={() => handleMarkerClick(group)}
            style={{ pointerEvents: "auto" }}
          >
            <g style={{ cursor: "pointer" }}>
              <circle
                r={group.projects.length > 1 ? 10 : 8}
                fill="#540000"
                stroke="#FFFFFF"
                strokeWidth={3}
                className="map-pin"
              />
              {group.projects.length > 1 && (
                <text
                  textAnchor="middle"
                  y="4"
                  style={{ 
                    fill: "#FFFFFF", 
                    fontSize: "10px",
                    fontWeight: "bold",
                    pointerEvents: "none"
                  }}
                >
                  {group.projects.length}
                </text>
              )}
            </g>
          </Marker>
        ))}
      </ComposableMap>
    </div>
  );
}

export default MyMap;

