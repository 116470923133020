import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/HomeMetrics.css';

function HomeMetrics() {
  const navigate = useNavigate();

  const handleMetricsClick = (e) => {
    e.preventDefault();
    navigate('/projects');
    setTimeout(() => {
      const metricsSection = document.querySelector('.metrics-section');
      if (metricsSection) {
        metricsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <div className="home-metrics-section">
      <h1 className="metrics-title">Our Earth Restoration Metrics</h1>
      <div className="home-metrics-container">
        <div className="home-metric-item">
          <div className="home-metric-number">15,539.71</div>
          <div className="home-metric-label">lbs/CO2 per year reduced</div>
        </div>
        <div className="home-metric-item">
          <div className="home-metric-number">$3,397.50</div>
          <div className="home-metric-label">raised for restoration projects</div>
        </div>
        <div className="home-metric-item">
          <div className="home-metric-number">124</div>
          <div className="home-metric-label">volunteers</div>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <Link 
          to="/projects" 
          onClick={handleMetricsClick}
          className="animated-underline"
          style={{ 
            color: "#ffffff",
            backgroundColor: "#540000",
            borderRadius: "20px",
            padding: "8px 20px",
            textDecoration: "none",
            fontSize: "1.3em",
            display: "inline-block"
          }}>
          See more metrics here.
        </Link>
      </div>
    </div>
  );
}

export default HomeMetrics; 