import React from 'react';
import '../styles/Legal.css';

function TermsAndConditions() {
  return (
    <div className="legal-container">
      <h1>Terms and Conditions</h1>
      <div className="legal-content">
        <section>
          <h2>1. Agreement to Terms</h2>
          <p>By accessing and using Ama Earth Group's website and services, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, you may not access our services.</p>
        </section>

        <section>
          <h2>2. Use License</h2>
          <p>Permission is granted to temporarily access the materials on Ama Earth Group's website for personal, non-commercial use only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
          <ul>
            <li>Modify or copy the materials</li>
            <li>Use the materials for any commercial purpose</li>
            <li>Remove any copyright or other proprietary notations from the materials</li>
          </ul>
        </section>

        <section>
          <h2>3. Disclaimer</h2>
          <p>The materials on Ama Earth Group's website are provided on an 'as is' basis. Ama Earth Group makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
        </section>

        <section>
          <h2>4. Limitations</h2>
          <p>In no event shall Ama Earth Group or its suppliers be liable for any damages arising out of the use or inability to use the materials on our website.</p>
        </section>

        <section>
          <h2>5. Contact Information</h2>
          <p>If you have any questions about these Terms and Conditions, please contact us at:</p>
          <p>community@amaearthgroup.com</p>
        </section>
      </div>
    </div>
  );
}

export default TermsAndConditions;
