import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/common.css';
import '../styles/AboutSub.css';

function AboutSub() {
  const navigate = useNavigate();

  const handleProjectsClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate('/projects');
  };

  return (
    <div className="about-sub">
      <div>
        <p>
          <span className="number-circle">1</span>
          On-the-ground restoration projects, led by Indigenous knowledge and manpowered by volunteers (like you!)
          <Link to="/projects" 
            onClick={handleProjectsClick}
            className="animated-underline"
            style={{ 
              color: "#ffffff",
              backgroundColor: "#540000",
              borderRadius: "20px",
              padding: "2px 8px",
              textDecoration: "none",
              marginLeft: "4px",
              fontSize: "0.9em"
            }}>
            Check out our projects here.
          </Link>
        </p>
        <br />
        <p>
          <span className="number-circle">2</span>
          An AI product that funds those restoration projects + streamlines workflow for climate tech professionals.
          <a 
            href="https://squid.amaearthgroup.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="animated-underline"
            style={{ 
              color: "#ffffff",
              backgroundColor: "#540000",
              borderRadius: "20px",
              padding: "2px 8px",
              textDecoration: "none",
              marginLeft: "4px",
              fontSize: "0.9em"
            }}>
            Check it out here.
          </a>
        </p>
      </div>
    </div>
  );
}

export default AboutSub; 