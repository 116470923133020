import React, { useEffect, useRef, useState } from "react";
import About from "./About";
import "../styles/Home.css";
import logo from '../images/ama-logo.png';
import backgroundVideo from '../videos/ama earth group homepage bg.mp4';

function Home() {
  const firstLineRef = useRef(null);
  const secondLineRef = useRef(null);
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  // Preload video
  useEffect(() => {
    const video = new Audio(backgroundVideo);
    video.preload = "auto";
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Reset animation state
            firstLineRef.current.classList.remove('visible');
            secondLineRef.current.classList.remove('visible');
            
            // Trigger animations with delay
            setTimeout(() => {
              firstLineRef.current.classList.add('visible');
            }, 800);
            
            setTimeout(() => {
              secondLineRef.current.classList.add('visible');
            }, 2000);
            
            // Stop observing once animation is triggered
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    // Observe the hero section
    if (firstLineRef.current) {
      observer.observe(firstLineRef.current.parentElement);
    }

    // Create a new observer for sections that should animate on scroll
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            sectionObserver.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observe all sections that should animate
    const sections = document.querySelectorAll('.section');
    sections.forEach((section) => {
      section.classList.remove('visible');
      sectionObserver.observe(section);
    });

    return () => {
      observer.disconnect();
      sectionObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('error', (e) => {
        console.error('Error loading video:', e);
      });
      
      videoRef.current.addEventListener('loadeddata', () => {
        console.log('Video loaded successfully');
        setIsVideoLoaded(true);
      });
    }
  }, []);

  return (
    <div className="Home">
      <section className="hero-section">
        {!isVideoLoaded && (
          <div className="video-placeholder">
            <div className="loading-spinner"></div>
          </div>
        )}
        <video
          ref={videoRef}
          className={`hero-video ${isVideoLoaded ? 'loaded' : ''}`}
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          style={{ backgroundColor: '#000' }}
          src={backgroundVideo}
        >
          Your browser does not support the video tag.
        </video>
        <div className="banner-content">
          <div className="banner-text-container">
            <h1 className="banner-title">
              <span ref={firstLineRef} className="first-line">
                Ama Earth Group
              </span>
              <span ref={secondLineRef} className="second-line">
                ETHICAL ENVIRONMENTAL AI
              </span>
            </h1>
          </div>
        </div>
      </section>

      <About />
      <footer className="home-footer">
        <img src={logo} alt="AMA Logo, a black background with a silver abstract image" className="footer-logo" />
      </footer>
    </div>
  );
}

export default Home;

// UNUSED OLD CODE:

  // const navigate = useNavigate();
  // const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  // useEffect(() => {
  //   function handleResize() {
  //     const vh = window.innerHeight * 0.01;
  //     document.documentElement.style.setProperty("--vh", `${vh}px`);
  //   }

  //   // Set the height on mount
  //   handleResize();

  //   // Set the resize listener
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the listener on component unmount
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // const projects = [
  //   { name: "Otamiri River, Nigeria 🇳🇬", slug: "otamiri-river" },
  //   { name: "Laguna del Condado, Puerto Rico 🇵🇷", slug: "laguna-del-condado" },
  //   {
  //     name: "Lagoa Rodrigo de Freitas, Brazil 🇧🇷",
  //     slug: "lagoa-rodrigo-de-freitas",
  //   },
  //   {
  //     name: "Leatherback Turtles, Puerto Rico 🐢",
  //     slug: "leatherback-turtles",
  //   },
  //   { name: "Passaic River, New Jersey 🇺🇸", slug: "passaic-river" },
  // ];

  // const handleProjectSelect = (event) => {
  //   const selectedProjectIndex = projects.findIndex(
  //     (p) => p.slug === event.target.value
  //   );
  //   if (selectedProjectIndex >= 0) {
  //     navigate("/projects", { state: { selectedProjectIndex } });
  //   }
  // };
