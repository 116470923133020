import React, { useState, useRef } from 'react';
import '../styles/FollowUs.css';

// Import images
import reel1 from '../assets/instagram/reel1.mp4';
import gto1 from '../assets/instagram/GTO1.png';
import gto2 from '../assets/instagram/GTO2.png';
import gto3 from '../assets/instagram/GTO3.png';
import gto4 from '../assets/instagram/GTO4.png';
import gto5 from '../assets/instagram/GTO5.png';
import gto6 from '../assets/instagram/GTO6.png';
import carousel1 from '../assets/instagram/1.png';
import carousel2 from '../assets/instagram/2.png';
import carousel3 from '../assets/instagram/3.png';
import carousel4 from '../assets/instagram/4.png';

function FollowUs() {
  const [hoveredPost, setHoveredPost] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const videoRef = useRef(null);

  const posts = [
    {
      type: 'reel',
      video: reel1,
      url: 'https://www.instagram.com/p/DHHptX0sPBN/'
    },
    {
      type: 'carousel',
      images: [gto1, gto2, gto3, gto4, gto5, gto6],
      url: 'https://www.instagram.com/p/DGRhQEOSbhH/'
    },
    {
      type: 'carousel',
      images: [carousel1, carousel2, carousel3, carousel4],
      url: 'https://www.instagram.com/p/DEleqsZM3G2/'
    }
  ];

  const handleMouseEnter = (index) => {
    setHoveredPost(index);
    if (posts[index].type === 'reel' && videoRef.current) {
      videoRef.current.play();
    } else if (posts[index].type === 'carousel') {
      // Start cycling through images
      const interval = setInterval(() => {
        setActiveImageIndex(prev => {
          const post = posts[index];
          return (prev + 1) % post.images.length;
        });
      }, 2000); // Change image every 2 seconds

      // Store interval ID to clear it later
      setHoveredPost(prev => ({
        index,
        interval
      }));
    }
  };

  const handleMouseLeave = (index) => {
    if (posts[index].type === 'reel' && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    } else if (posts[index].type === 'carousel') {
      // Clear the interval when mouse leaves
      if (hoveredPost && hoveredPost.interval) {
        clearInterval(hoveredPost.interval);
      }
    }
    setHoveredPost(null);
    setActiveImageIndex(0);
  };

  return (
    <div className="follow-content">
      <p className="section-text">
        Stay connected with us on Instagram for the latest updates on our environmental initiatives and community projects.
      </p>
      <div className="reels-grid">
        {posts.map((post, index) => (
          <a 
            key={index}
            href={post.url}
            target="_blank"
            rel="noopener noreferrer"
            className="reel-container"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            {post.type === 'reel' && (
              <video
                ref={videoRef}
                src={post.video}
                className="reel-video"
                muted
                loop
                playsInline
              />
            )}
            {post.type === 'carousel' && (
              <div className="carousel-container">
                {post.images.map((image, imgIndex) => (
                  <img 
                    key={imgIndex}
                    src={image}
                    alt={`Carousel image ${imgIndex + 1}`}
                    className={`carousel-image ${hoveredPost?.index === index && imgIndex === activeImageIndex ? 'active' : ''}`}
                  />
                ))}
              </div>
            )}
            {post.type === 'photo' && (
              <img 
                src={post.image}
                alt="Instagram post"
                className="reel-image"
              />
            )}
          </a>
        ))}
      </div>
      <div className="social-media-container">
        <a 
          href="https://www.instagram.com/amaearthgroup/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="instagram-button"
        >
          <div className="instagram-icon">
            <svg viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.012-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
            </svg>
          </div>
          <span>Follow Us</span>
        </a>
      </div>
    </div>
  );
}

export default FollowUs; 