import React, { useEffect, useRef } from 'react';
import '../styles/common.css';
import '../styles/Banner.css';

function Banner() {
  const firstLineRef = useRef(null);
  const secondLineRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Reset animation state
            firstLineRef.current.classList.remove('visible');
            secondLineRef.current.classList.remove('visible');
            
            // Trigger animations with delay
            setTimeout(() => {
              firstLineRef.current.classList.add('visible');
            }, 500);
            
            setTimeout(() => {
              secondLineRef.current.classList.add('visible');
            }, 1200);
            
            // Stop observing once animation is triggered
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (firstLineRef.current) {
      observer.observe(firstLineRef.current.parentElement);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section 
      id="banner"
      className="banner-section"
      style={{
        backgroundImage: `url('/images/ama_splash_circle.jpeg')`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        position: 'relative'
      }}
    >
      <div className="banner-content">
        <div className="banner-text-container">
          <h1 className="banner-title">
            <span ref={firstLineRef} className="first-line">
              One company.
            </span>
            <span ref={secondLineRef} className="second-line">
              TWO BOLD MISSIONS.
            </span>
          </h1>
        </div>
      </div>
    </section>
  );
}

export default Banner; 