import React, { useEffect } from 'react';
import '../styles/Metrics.css';

function Metrics() {
  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="metrics-section">
      <h1>Our Impact</h1>
      <div className="metrics-container">
        <div className="metrics-wrapper">
          <div className="metric-item">
            <div className="metric-number">15,539.71</div>
            <div className="metric-label">lbs/CO2 per year reduced</div>
          </div>
          <div className="metric-item">
            <div className="metric-number">124</div>
            <div className="metric-label">volunteers</div>
          </div>
          <div className="metric-item">
            <div className="metric-number">$3,397.50</div>
            <div className="metric-label">raised for restoration projects</div>
          </div>
          <div className="metric-item">
            <div className="metric-number">7150</div>
            <div className="metric-label">pieces of litter picked up</div>
          </div>
          <div className="metric-item">
            <div className="metric-number">252</div>
            <div className="metric-label">trees planted</div>
          </div>
          <div className="metric-item">
            <div className="metric-number">5150</div>
            <div className="metric-label">invasive species removed</div>
          </div>
          <div className="metric-item">
            <div className="metric-number">50</div>
            <div className="metric-label">steel poles installed for turtle nesting</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Metrics; 