import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import MyMap from "../components/MyMap";
import Metrics from "../components/Metrics";
import { projectsData } from "../data/projects";
import "../styles/Projects.css";
import { useAuth } from "../auth/authProvider";
import { useUI } from "../utils/UIContext";

function Projects() {
  const auth = useAuth();
  const location = useLocation();
  const { selectedProjectIndex = 0 } = location.state || {};
  const [selectedProjects, setSelectedProjects] = useState(null);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    // Scroll to description when component mounts
    if (descriptionRef.current) {
      try {
        descriptionRef.current.scrollIntoView({ behavior: 'instant' });
      } catch (error) {
        console.log('Scroll behavior not supported');
      }
    }
  }, []);

  const handleCloseModal = () => {
    setIsProjectModalOpen(false);
  };

  function Collapsible({ title, children, isOpenByDefault = false }) {
    const [isOpen, setIsOpen] = useState(isOpenByDefault);

    return (
      <div>
        <div className="collapsible-header" onClick={() => setIsOpen(!isOpen)}>
          {title} {isOpen ? "▲" : "▼"}
        </div>
        {isOpen && <div className="collapsible-content">{children}</div>}
      </div>
    );
  }

  return (
    <div className="project-page projects-background">
      <div className="map-description" ref={descriptionRef}>
        <h2>Our Restoration Projects</h2>
        <p>Explore our restoration projects around the world. Each pin represents a location where we're making a difference. Click on a pin to learn more about the projects and their impact.</p>
      </div>
      <div className="map-section">
        <MyMap onProjectSelect={(projects) => {
          setSelectedProjects(projects);
          setIsProjectModalOpen(true);
        }} />
      </div>
      <Metrics />
      {isProjectModalOpen && selectedProjects && (
        <div className="project-modal" onClick={handleCloseModal}>
          <div className="project-modal-content" onClick={e => e.stopPropagation()}>
            <button 
              className="close-button" 
              onClick={handleCloseModal}
              aria-label="Close project details"
            >
              ×
            </button>
            {selectedProjects.map((project, index) => (
              <div key={index} className="project-container">
                <div className="slide-header">
                  <h2 className="hd">{project.title}</h2>
                </div>
                <img
                  src={project.img}
                  alt={project.title}
                  className={`project-image ${project.title.includes("Nigeria") ? "nigeria-project-image" : ""} ${project.title.includes("Leatherback") ? "turtle-project-image" : ""}`}
                />
                {project.img_credit && (
                  <p className="image-credit" dangerouslySetInnerHTML={{ __html: project.img_credit }} />
                )}
                <div className="project-content">
                  <div className="project-details sub">
                    <Collapsible
                      title="Problem & Solution:"
                      isOpenByDefault={true}
                    >
                      <p>
                        <div className="sub" style={{ color: "black" }}>
                          <p>
                            <strong>Problem:</strong> {project.problem}
                          </p>
                          <p>
                            <strong>Solution:</strong> {project.solution}
                          </p>
                        </div>
                      </p>
                    </Collapsible>
                    <Collapsible title="Leading Organizations:">
                      <ul className="sub">
                        {project.leading_org.map((org, orgIndex) => (
                          <li key={orgIndex}>
                            <a
                              className="org-links"
                              href={project.leading_org_url[orgIndex]}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {org}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Collapsible>
                    <Collapsible title="Supporting Businesses">
                      <ul className="sub">
                        {Array.isArray(project.contributing_org_name) ? (
                          project.contributing_org_name.map((org, orgIndex) => (
                            <li key={orgIndex}>
                              <a
                                className="org-links"
                                href={Array.isArray(project.contributing_org_url) 
                                  ? project.contributing_org_url[orgIndex] 
                                  : project.contributing_org_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {org}
                              </a>
                            </li>
                          ))
                        ) : project.contributing_org_name === "N/A" ? (
                          <li>N/A</li>
                        ) : (
                          <li>
                            <a
                              className="org-links"
                              href={project.contributing_org_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {project.contributing_org_name}
                            </a>
                          </li>
                        )}
                      </ul>
                    </Collapsible>
                    <Collapsible title="Total Funds Raised:" className="hd">
                      <p className="sub" style={{ color: "black" }}>
                        ${project.dedicated_funds || 0}
                      </p>
                    </Collapsible>
                    <Collapsible
                      title="Progress"
                      style={{ fontFamily: "Raleway" }}
                    >
                      <ul className="sub">
                        {project.progress_url && 
                         project.progress_url !== "TBD" && 
                         project.progress_url !== "N/A" && (
                          <li>
                            <a
                              className="org-links"
                              href={project.progress_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {project.progress_title || "View Progress"}
                            </a>
                          </li>
                        )}
                        {(!project.progress_url || 
                          project.progress_url === "TBD" || 
                          project.progress_url === "N/A") && (
                          <li>N/A</li>
                        )}
                      </ul>
                    </Collapsible>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Projects;
