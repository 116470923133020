import React, { useEffect } from "react";
import '../styles/common.css';
import Banner from '../components/Banner';
import AboutSub from '../components/AboutSub';
import Product from '../components/Product';
import MeetTheTeam from "./MeetTheTeam";
import FollowUs from '../components/FollowUs';
import HomeMetrics from "../components/HomeMetrics";

function About() {
  useEffect(() => {
    // Create intersection observer for section transitions
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          // Once the animation has played, we can stop observing this element
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1, // Trigger when even 10% of the element is visible
      rootMargin: '0px 0px -50px 0px' // Trigger slightly before the element comes into view
    });

    // Observe all sections that should animate
    const sections = document.querySelectorAll('.section.animate');
    sections.forEach((section) => {
      // Reset the initial state
      section.classList.remove('visible');
      observer.observe(section);
    });

    // Also observe the follow section specifically
    const followSection = document.querySelector('.follow-section');
    if (followSection) {
      followSection.classList.remove('visible');
      observer.observe(followSection);
    }

    return () => observer.disconnect();
  }, []);

  // Make this function available to the parent component if needed
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <div className="about-page">
      <section className="section banner-section">
        <Banner />
      </section>
      <div id="about-sub" className="section">
        <AboutSub />
      </div>
      <div id="home-metrics" className="section">
        <HomeMetrics />
      </div>
      <section id="product" className="section animate product-section">
        <Product />
      </section>
      <section id="team" className="section animate team-section">
        <div className="content-wrapper">
          <h2 className="section-title">Meet Our Team</h2>
          <MeetTheTeam />
        </div>
      </section>
      <section className="section animate follow-section">
        <div className="content-wrapper">
          <h2 className="section-title">Follow Us</h2>
          <FollowUs />
        </div>
      </section>
    </div>
  );
}

// Export both the component and the scroll function
export const scrollToAboutSection = (sectionId) => {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  }
};

export default About;

// UNUSED OLD CODE:

{/* <h1 className="hero-title">Restoring Nature. Empowering Communities. Regenerating Ecosystems.</h1> */}

{/* <p className="section-text">
          Ama Earth Group integrates indigenous wisdom with advanced climate technologies—from AI to geospatial analytics—to heal the planet. Our approach leverages modern indigeneity, combining cutting-edge tools with ancestral knowledge to drive environmental regeneration at the local level, fostering thriving ecosystems and bringing balance back to nature.
          </p> */}

{/* <p className="section-text">
          We see a planet where hyper-local climate actions ripple into global impact, bringing life back to our forests, rivers, oceans, and skies. Guided by the principles of modern indigeneity, we honor the Earth's rhythms and cycles while driving innovation that regenerates our environment.
          </p> */}
