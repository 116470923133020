import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Metrics from "./components/Metrics";
import "./styles/App.css";
import Error404 from "./pages/error404";
import { UIProvider } from "./utils/UIContext";
import AlertBar from "./components/Alertbar";
import NavBarBottom from "./components/NavBarBottom";
import MyMap from "./components/MyMap";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Indigenous from "./pages/Indigenous";
import MissionVisionPage from "./pages/MissionVision";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [showNewsletter, setShowNewsletter] = useState(true);

  const closeNewsletterModal = () => {
    setShowNewsletter(false);
  };

  return (
    <Router>
      <UIProvider>
        <div className="App">
          <ScrollToTop />
          <Navbar />
          <AlertBar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/metrics" element={<Metrics />} />
              <Route path="/map" element={<MyMap />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/indigenous" element={<Indigenous />} />
              <Route path="/mission-vision" element={<MissionVisionPage />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </main>
          <NavBarBottom />
        </div>
      </UIProvider>
    </Router>
  );
}

export default App;
