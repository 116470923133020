import React from 'react';
import '../styles/Legal.css';

function PrivacyPolicy() {
  return (
    <div className="legal-container">
      <h1>Privacy Policy</h1>
      <div className="legal-content">
        <section>
          <h2>1. Introduction</h2>
          <p>At Ama Earth Group, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.</p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <p>We collect information that you voluntarily provide to us when you use our services, including:</p>
          <ul>
            <li>Contact information (name, email address)</li>
            <li>Communication preferences</li>
            <li>Feedback and correspondence</li>
          </ul>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide and maintain our services</li>
            <li>Respond to your inquiries and requests</li>
            <li>Send you updates and communications</li>
            <li>Improve our services and user experience</li>
          </ul>
        </section>

        <section>
          <h2>4. Data Security</h2>
          <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>
        </section>

        <section>
          <h2>5. Contact Us</h2>
          <p>If you have questions about this Privacy Policy, please contact us at:</p>
          <p>community@amaearthgroup.com</p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;