// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NavBarBottom.css';
import { useAuth } from '../auth/authProvider';
import twitter from '../images/4.svg';
import instagram from '../images/5.svg';
import facebook from '../images/6.svg';
import tiktok from '../images/7.svg';
import linkedin from '../images/8.svg';

function NavBarBottom() {
  const auth = useAuth();

  const handleEmailClick = (e) => {
    e.preventDefault();
    window.location.href = 'mailto:community@amaearthgroup.com';
  };

  return (
    <nav className="navbar">
      {/* Aurora Borealis Effect */}
      <div className="aurora-container">
        <svg viewBox="0 0 1440 400" preserveAspectRatio="none">
          <defs>
            <linearGradient id="aurora1" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#00ff87', stopOpacity: 0.7 }}>
                <animate 
                  attributeName="stop-color" 
                  values="#00ff87;#9d00ff;#ff0055;#00ff87" 
                  dur="8s" 
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="100%" style={{ stopColor: '#000000', stopOpacity: 0.9 }} />
            </linearGradient>
            <linearGradient id="aurora2" x1="100%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#ff0055', stopOpacity: 0.7 }}>
                <animate 
                  attributeName="stop-color" 
                  values="#ff0055;#00ff87;#9d00ff;#ff0055" 
                  dur="10s" 
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="100%" style={{ stopColor: '#000000', stopOpacity: 0.9 }} />
            </linearGradient>
          </defs>
          
          <path 
            className="animate-float-slow"
            fill="url(#aurora1)"
            d="M0,200 Q360,250 720,200 T1440,200 V400 H0 Z"
          >
            <animate
              attributeName="d"
              values="
                M0,200 Q360,250 720,200 T1440,200 V400 H0 Z;
                M0,200 Q360,150 720,200 T1440,200 V400 H0 Z;
                M0,200 Q360,250 720,200 T1440,200 V400 H0 Z
              "
              dur="20s"
              repeatCount="indefinite"
            />
          </path>
          <path 
            className="animate-float-medium"
            fill="url(#aurora2)"
            d="M0,200 Q360,150 720,200 T1440,200 V400 H0 Z"
          >
            <animate
              attributeName="d"
              values="
                M0,200 Q360,150 720,200 T1440,200 V400 H0 Z;
                M0,200 Q360,250 720,200 T1440,200 V400 H0 Z;
                M0,200 Q360,150 720,200 T1440,200 V400 H0 Z
              "
              dur="15s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>

      <div className="navbar-content">
        <ul className="nav-links">
          <li><a href="#" onClick={handleEmailClick} className="nav-item">Get In Touch</a></li>
          <br />
          <li><Link to="/privacy-policy" className="nav-item">Privacy Policy</Link></li>
          <br />
          <li><Link to="/terms-and-conditions" className="nav-item">Terms and Conditions</Link></li>
        </ul>

        <div className="social-icons">
          <a href="https://www.instagram.com/amaearthgroup/" target="_blank" rel="noopener noreferrer" className="social-link">
            <img src={instagram} alt="Instagram" className="social-icon" />
          </a>
          <a href="https://twitter.com/amaearthgroup" target="_blank" rel="noopener noreferrer" className="social-link">
            <img src={twitter} alt="Twitter" className="social-icon" />
          </a>
          <a href="https://www.tiktok.com/@amaearthgroup" target="_blank" rel="noopener noreferrer" className="social-link">
            <img src={tiktok} alt="TikTok" className="social-icon" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61550248906282#" target="_blank" rel="noopener noreferrer" className="social-link">
            <img src={facebook} alt="Facebook" className="social-icon" />
          </a>
          <a href="https://www.linkedin.com/company/amaearthgroup/posts/?feedView=all" target="_blank" rel="noopener noreferrer" className="social-link">
            <img src={linkedin} alt="LinkedIn" className="social-icon" />
          </a> 
        </div> 
      </div>
    </nav>
  );
}

export default NavBarBottom;

// OLD UNUSED CODE:

 {/* <li><Link to="/memberships" className="nav-item">Memberships</Link></li> */}
{/* <li><Link to="/leaderboard" className="nav-item">Leaderboard</Link></li> */}
        {/* {auth.user && auth.user.is_admin ? (
          <li><Link to="/admin" className="nav-item">Admin Dashboard</Link></li>
        ): (
          <li><Link to="/dashboard" className="nav-item">Dashboard</Link></li>
        )} */}

        {/* <li className="game-tab"><Link to="/" className="nav-item">Giveaway</Link><p>New!</p></li> */}
        {/* <li className="game-tab"><Link to="/lobby" className="game-tab-text">Game</Link></li> */}
        {/* <li><Link to="/profile" className="nav-item">Profile</Link></li> */}