import React, { useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../images/ama-logo.png";
import "../styles/Navbar.css";
import { useAuth } from "../auth/authProvider";
import { useUI } from "../utils/UIContext";

function Navbar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleNewAlert, handleAlertType } = useUI();
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [announcement, setAnnouncement] = useState('');
  const timeoutRef = useRef(null);
  const announcementTimeoutRef = useRef(null);

  const announceMessage = (message) => {
    setAnnouncement(message);
    if (announcementTimeoutRef.current) {
      clearTimeout(announcementTimeoutRef.current);
    }
    announcementTimeoutRef.current = setTimeout(() => {
      setAnnouncement('');
    }, 3000);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const handleLinkClick = (sectionId) => {
    setExpandedMenu(null);
    
    // If we're not on the homepage and trying to navigate to a section
    if (location.pathname !== '/' && sectionId) {
      navigate('/');
      // Wait for navigation to complete before scrolling
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100);
    } 
    // If we're already on the homepage, just scroll
    else if (sectionId) {
      scrollToSection(sectionId);
    }
  };

  const handleMouseEnter = (menu) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setExpandedMenu(menu);
    announceMessage(`${menu} menu expanded`);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setExpandedMenu(null);
      announceMessage('Menu closed');
    }, 500);
  };

  const handleKeyDown = (e, menu) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setExpandedMenu(expandedMenu === menu ? null : menu);
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setExpandedMenu(null);
    } else if (e.key === 'ArrowDown' && expandedMenu) {
      e.preventDefault();
      const dropdown = e.target.querySelector('.dropdown-content');
      if (dropdown) {
        const firstItem = dropdown.querySelector('.dropdown-item');
        firstItem?.focus();
      }
    }
  };

  const handleDropdownKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      const items = Array.from(e.target.parentElement.querySelectorAll('.dropdown-item'));
      const currentIndex = items.indexOf(e.target);
      const nextIndex = e.key === 'ArrowDown' 
        ? (currentIndex + 1) % items.length 
        : (currentIndex - 1 + items.length) % items.length;
      items[nextIndex].focus();
    }
  };

  const handleExternalLink = (e) => {
    handleNewAlert('Opening product in new tab');
    handleAlertType('info');
  };

  return (
    <div className="navbar-container">
      <div 
        role="status" 
        aria-live="polite" 
        className="sr-only"
        style={{ position: 'absolute', width: '1px', height: '1px', padding: '0', margin: '-1px', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', whiteSpace: 'nowrap', border: '0' }}
      >
        {announcement}
      </div>

      <a href="#main-content" className="skip-link" aria-label="Skip to main content">
        Skip to main content
      </a>
      <nav className="navbar-top" role="navigation" aria-label="Main navigation">
        <Link to="/" className="navbar-brand" aria-label="Home">
          <img src={logo} alt="AMA Earth Group Logo" className="navbar-logo" />
        </Link>

        <ul className="nav-links" role="menubar" aria-label="Main menu">
          <li className="nav-item" 
              onMouseEnter={() => handleMouseEnter("about")}
              onMouseLeave={handleMouseLeave}
              onKeyDown={(e) => handleKeyDown(e, "about")}
              role="menuitem"
              aria-haspopup="true"
              aria-expanded={expandedMenu === "about"}
              aria-label="About menu"
              tabIndex="0"
          >
            <span>About</span>
            <span aria-hidden="true">+</span>
            {expandedMenu === "about" && (
              <div className="dropdown-wrapper" role="menu" aria-label="About submenu">
                <ul className="dropdown-content">
                  <div className="dropdown-item" 
                       onClick={() => handleLinkClick('about-sub')} 
                       onKeyDown={(e) => {
                         handleDropdownKeyDown(e);
                         if (e.key === 'Enter') handleLinkClick('about-sub');
                       }}
                       role="menuitem" 
                       tabIndex="0"
                       aria-label="How It Works">How It Works</div>
                  <div className="dropdown-item" 
                       onClick={() => handleLinkClick('product')}
                       onKeyDown={(e) => {
                         handleDropdownKeyDown(e);
                         if (e.key === 'Enter') handleLinkClick('product');
                       }}
                       role="menuitem" 
                       tabIndex="0"
                       aria-label="Product information">Product</div>
                  <div className="dropdown-item" 
                       onClick={() => handleLinkClick('team')}
                       onKeyDown={(e) => {
                         handleDropdownKeyDown(e);
                         if (e.key === 'Enter') handleLinkClick('team');
                       }}
                       role="menuitem" 
                       tabIndex="0"
                       aria-label="Meet Our Team">Meet Our Team</div>
                  <Link to="/mission-vision" 
                        className="dropdown-item" 
                        onClick={() => setExpandedMenu(null)}
                        role="menuitem" 
                        tabIndex="0"
                        aria-label="Mission and Vision">Mission & Vision</Link>
                  <Link to="/indigenous" 
                        className="dropdown-item" 
                        onClick={() => setExpandedMenu(null)}
                        role="menuitem" 
                        tabIndex="0"
                        aria-label="Indigenous Statement">Indigenous Statement</Link>
                </ul>
              </div>
            )}
          </li>

          <li className="nav-item"
              onMouseEnter={() => handleMouseEnter("restoration")}
              onMouseLeave={handleMouseLeave}
              onKeyDown={(e) => handleKeyDown(e, "restoration")}
              role="menuitem"
              aria-haspopup="true"
              aria-expanded={expandedMenu === "restoration"}
              aria-label="Restoration menu"
              tabIndex="0"
          >
            <span>Restoration</span>
            <span aria-hidden="true">+</span>
            {expandedMenu === "restoration" && (
              <div className="dropdown-wrapper" role="menu" aria-label="Restoration submenu">
                <ul className="dropdown-content">
                  <Link to="/projects" 
                        className="dropdown-item" 
                        onClick={() => setExpandedMenu(null)}
                        onKeyDown={handleDropdownKeyDown}
                        role="menuitem" 
                        tabIndex="0"
                        aria-label="View Projects">Projects</Link>
                  <Link to="/projects" 
                        className="dropdown-item" 
                        onClick={(e) => {
                          e.preventDefault();
                          setExpandedMenu(null);
                          navigate('/projects');
                          setTimeout(() => {
                            const metricsSection = document.querySelector('.metrics-section');
                            if (metricsSection) {
                              metricsSection.scrollIntoView({ behavior: 'smooth' });
                            }
                          }, 100);
                        }}
                        onKeyDown={handleDropdownKeyDown}
                        role="menuitem" 
                        tabIndex="0"
                        aria-label="View Metrics">Metrics</Link>
                  <a 
                    href="https://lwxfa11kjyl.typeform.com/to/csC9iw9v"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      setExpandedMenu(null);
                      handleExternalLink(e);
                    }}
                    className="dropdown-item" 
                    role="menuitem" 
                    tabIndex="0"
                    aria-label="Get Involved with projects">Get Involved</a>
                </ul>
              </div>
            )}
          </li>

          <li
              onMouseEnter={() => handleMouseEnter("product")}
              onMouseLeave={handleMouseLeave}
              className="nav-item"
              role="menuitem"
              tabIndex="0"
          >
            <a 
              href="https://squid.amaearthgroup.com//"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleExternalLink}
              aria-label="Open product in new tab (opens in new window)"
              style={{ 
                color: "#ffffff",
                backgroundColor: "#540000",
                borderRadius: "20px",
                padding: "12px 24px",
                cursor: "pointer",
                textDecoration: "none"
              }}
            >
              Product
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;

